import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import { ITheme } from '@/shared/styles/MuiTheme'
import WebsiteHeader from '../WebsiteHeader/WebsiteHeader'
import { useAuth } from 'contexts/auth'
import { useClub } from 'contexts/club'
import WebsiteDesktopMenu from '../WebsiteMenu/WebsiteDesktopMenu'
import WebsiteMobileMenu from '../WebsiteMenu/WebsiteMobileMenu'
import { IWebsiteMenu } from '@/shared/models/Website'
import { IFlfThemeProps } from '@/shared/models/Club'


const useStyles = makeStyles((theme: ITheme) => ({
    navMainLayout: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    header: {
        backgroundColor: ({ isFlf }: IFlfThemeProps) => !isFlf ? theme.props.sectionMode.accent : '#FFFFFF' ,
        position: 'relative',
        zIndex: 1,
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            background: ({ isFlf }: IFlfThemeProps) => isFlf ? 'linear-gradient(270deg, rgba(19, 19, 19, 0.2) 0%, rgba(255, 255, 255, 0) 99.2%)' : 'linear-gradient(270deg, rgba(19, 19, 19, 0.2) 0%, rgba(19, 19, 19, 0) 100%)',
        }
    },
    navHeaderInner: {
        position: 'relative',
        zIndex: 1,
        display: 'flex',
        height: ({ isFlf }: IFlfThemeProps) => isFlf ? 64 : 74,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            height: '64px',
            padding: theme.spacing(0, 3),
        }
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    }
}))

interface IProps {
    hideUserTopNavBar?: boolean;
    isPreview?: boolean
    previewMenus?: IWebsiteMenu[]
}

export default function WebsiteNavBar({ hideUserTopNavBar, isPreview, previewMenus }: IProps): JSX.Element {
    const { website, isFlf } = useClub()
    const { isUserAdmin } = useAuth()
    const classes = useStyles({ isFlf })
    const [ isMobileMenuOpen, setIsMobileMenuOpen ] = useState(false)

    if (!website?.displayed && !isUserAdmin) {
        return null
    }

    function openMobileMenu(): void {
        if (!isMobileMenuOpen) {
            setIsMobileMenuOpen(true)
        }
    }

    function closeMobileMenu(): void {
        if (isMobileMenuOpen) {
            setIsMobileMenuOpen(false)
        }
    }

    return (
        <header className={classes.navMainLayout}>
            <div className={classes.header}>
                <Container maxWidth="lg" className={classes.navHeaderInner}>
                    <WebsiteHeader handleMobileMenuOpen={openMobileMenu} />
                </Container>
            </div>
            <WebsiteMobileMenu
                isOpen={isMobileMenuOpen}
                handleMobileMenuClose={closeMobileMenu}
                isPreview={isPreview}
                previewMenus={previewMenus}
            />
            <WebsiteDesktopMenu
                hideUserTopNavBar={hideUserTopNavBar}
                isPreview={isPreview}
                previewMenus={previewMenus}
            />
        </header>
    )
}