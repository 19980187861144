import React from 'react'
import { FormattedMessage } from 'react-intl'

import { menuStyles } from './styles'

interface IProps {
    icon: string;
    name: string;
    items: {
        id: number | string;
        link: string;
        icon?: string;
        name: string;
        has_access: boolean;
    }[];
}

export default function AdminMenuItem({
    icon,
    name,
    items
}: IProps): JSX.Element {
    const menuClasses = menuStyles()

    return <div className={menuClasses.menuItem}>
        <div className={menuClasses.menuItemInner}>
            <h3 className={menuClasses.menuGroupTitle}>
                {icon && <img src={getMenuIconPath({ iconName: items.some(i => i.has_access) ? icon : 'lock_closed', isBlack: false })} className={menuClasses.menuIconItem} alt='menu item' />}
                <FormattedMessage id={name} defaultMessage={name} />
            </h3>
            <ul className={menuClasses.menuSubitems}>
                {items.map(subitem => (
                    <li className={menuClasses.menuSubitem} key={subitem.id}>
                        {(subitem.icon && subitem.has_access) && <>
                            <img src={getMenuIconPath({ iconName: subitem.icon, isBlack: false })} className={menuClasses.menuIconItem} />
                            <img src={getMenuIconPath({ iconName: subitem.icon, isBlack: true })} className={menuClasses.menuIconItemBlack} />
                        </>}
                        {
                            subitem.has_access ?
                                <a className={menuClasses.menuLink} href={subitem.link}>
                                    <FormattedMessage id={subitem.name} defaultMessage={subitem.name} />
                                </a>
                                :
                                <div className={menuClasses.menuLinkDisabled}>
                                    <img src={getMenuIconPath({ iconName: 'lock_closed', isBlack: false })} className={menuClasses.menuIconItemLock}/>
                                    <FormattedMessage id={subitem.name} defaultMessage={subitem.name} />
                                </div>
                        }
                    </li>
                ))}
            </ul>
        </div>
    </div>
}

function getMenuIconPath({ iconName, isBlack }: { iconName: string, isBlack: boolean }): string {
    return `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}/menu/${iconName}${isBlack ? '_black' : ''}.svg`
}
