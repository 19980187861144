import { useAuth } from 'contexts/auth'
import { useClub } from 'contexts/club'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { IProps } from './types'
import { getPackageTargeting, getUserAgeTargeting, detectColorset } from './utils'

export const AdManager: FunctionComponent<IProps> = ({
    sizes,
    color,
    adType,
    blockId,
    sizeMapping,
    targetingParams,
    keepSlotOnUnmount = false
}) => {
    if (typeof window === 'undefined') {
        return <></>
    }

    const { club } = useClub()
    const { user } = useAuth()

    let initialTargetingParams = {}

    const clubID = club.id
    const countryId = club?.country.id ?? null
    const language = user?.language
    const sportId = club?.sport?.id ?? null
    const packageName = club?.package ?? null
    const birthday = user?.birthday ?? null
    const sex = user?.sex

    initialTargetingParams = {
        clubID,
        ...clubID ? {clubs_that_accepted: [clubID]} : {},
        ...countryId ? {c: countryId} : {},
        l: language,
        ...sportId ? {a: sportId} : {},
        ...packageName ? {p: getPackageTargeting(packageName)} : {},
        ...birthday? {uag: getUserAgeTargeting(birthday)} : {},
        x: isNaN(parseInt(sex)) ? 3 : parseInt(sex) + 1,
        ...color ? { colorset: detectColorset(color) } : {}
    }

    const [adBlockId] = useState(`${adType}${blockId}`)
    const mergedTargetingParams = {...initialTargetingParams, ...targetingParams}
    const networkID = process.env.NEXT_PUBLIC_GOOGLE_ADS_NETWORK_ID
    const googletag = (window as any).googletag ?? { cmd: [] }
    const [savedSlot, setSavedSlot] = useState(null);

    const setupTag = useCallback(()=> {
        if (typeof googletag.defineSlot === 'undefined' || savedSlot) {
            return
        }

        googletag.cmd.push(() => {
            googletag.pubads().enableAsyncRendering();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
        });

        const slot = googletag.defineSlot(`/${networkID}/${adType}`, sizes, adBlockId)

        if (!slot) {
            return
        }

        setSavedSlot(slot)

        Object.keys(mergedTargetingParams).forEach(key => {
            slot.setTargeting(key, mergedTargetingParams[key])
        })

        if (sizeMapping) {
            let mapping = googletag.sizeMapping()
                .addSize([1024, 768], [[300, 50], [234, 60]])
                .addSize([300, 90], [[300, 50], [234, 60]])
            mapping = mapping.build()
            slot.defineSizeMapping(mapping)
        }
        slot.addService(googletag.pubads())
        googletag.display(adBlockId)
        googletag.pubads().refresh([slot])
    }, [blockId, adType, sizes])

    useEffect(() => {
        setTimeout(() => setupTag(), 1000)

        return () => {
            if (typeof googletag.defineSlot === 'undefined') {
                return
            }
            if (!keepSlotOnUnmount) {
                googletag.destroySlots()
                googletag.pubads().clear()
                setSavedSlot(null)
            }
        }
    }, [blockId, adType, sizes])

    return !googletag._loaded ? <div id={adBlockId} /> : <div>load</div>
}
